/* ----------- Global CSS | intab.io ------------ */
div.divinfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  line-height: 1px;
  background: #FFEB3B;
  border: 3px solid #000000;
  border-radius: 10px;
  box-shadow: 4px 3px 10px 3px #000000;
  margin-top: 6px;
  margin: 1rem;
  padding: 0rem;
}

form {
  display: flex;
  justify-items: stretch;
  align-items: center;
  justify-content: center;
  align-content: space-evenly;
  flex-direction: column;
  background: #FFEB3B;
  box-shadow: 4px 3px 10px 4px #000000;
  padding: 4rem;
  margin: 1rem;
  border: 4px solid #000000;
  border-radius: 10px;
}

label {
  display: none;
}

input {
  padding: 10px;
  margin: 5px;
  text-align: center;
  font-family: monospace;
  font-weight: 900;
  font-size: 16px;
  line-height: 17px;
  background: #FFEB3B;
  border-radius: 5px;
}

button {
  border-radius: 5px;
  padding: 1rem;
  margin: 1rem;
  background: #FFC107;
}


